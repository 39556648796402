<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    :class="{ hidden: this.modalDelete.isOpen == false }"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-w-lg w-full sm:align-middle sm:my-8"
      >
        <div class="p-4">
          <p class="text-2xl mb-4 text-gray-900">Confirmation</p>
          <p class="text-gray-900">
            Are you sure want to delete the selected product from this customer?
          </p>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            @click="
              deleteCustomersProducts(
                this.modalDelete.selectedId,
                this.modalDelete.selectedCustomerId
              )
            "
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <div v-if="loading" class="btn-spinner mt-1 mr-2" />
            Yes, Delete Product
          </button>
          <button
            @click="closeModalDelete()"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DeleteCustomersProductsByCustomer",
  computed: {
    ...mapState("customersProducts", {
      errors: (state) => state.errors,
      modalDelete: (state) => state.modalDelete,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    closeModalDelete() {
      this.$store.dispatch("customersProducts/openModalDelete", {
        isOpen: false,
        selectedId: null,
        selectedCustomerId: null,
      });
    },
    deleteCustomersProducts(selectedId, selectedCustomerId) {
      this.$store.dispatch("customersProducts/deleteCustomersProducts", {
        id: selectedId,
        customerId: selectedCustomerId,
      });
    },
  },
};
</script>
